import logo from './logo.png';
import './App.css';
import { QRCode } from 'react-qrcode-logo';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <a href="https://www.juicebox.so" className="App-logo-container">
          <img src={logo} alt="logo" className="App-logo" />
        </a>
        <div className="QR-container">
          <p className="App-text">
            Scan or tap the QR code
            <br />
            to gain access
          </p>
          <a href="https://apps.apple.com/us/app/juicebox-lifestyle-health/id6468025970" className='QR-link'>
            <div className='QR-body'>
              <QRCode
                // ecLevel='Q'
                size={236}
                qrStyle='dots'
                bgColor='transparent'
                eyeRadius={10}
                value="https://apps.apple.com/us/app/juicebox-lifestyle-health/id6468025970" />
            </div>
          </a>
        </div>
        {/* <a
          className="App-link"
          href="https://juicebox.so"
          target="_blank"
          rel="noopener noreferrer"
        >
          Juicebox
        </a> */}
      </header >
    </div >
  );
}

export default App;
